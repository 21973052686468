import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../components/layout';

export default () => {
  const [captchaResponse, setCaptchaResponse] = React.useState('');

  React.useEffect(() => {
    grecaptcha.ready(() => {
      grecaptcha
        .execute('6LcYKt0mAAAAAFMzlarV3o6zvwsxGGUTE7e1XooB', {
          action: 'homepage'
        })
        .then(token => {
          setCaptchaResponse(token);
        });
    });
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Contact</title>
        <script src="https://www.google.com/recaptcha/api.js?render=6LcYKt0mAAAAAFMzlarV3o6zvwsxGGUTE7e1XooB" />
      </Helmet>
      <div>
        <h1>Contact Me</h1>
        <form
          action="https://getform.io/f/16d34735-0e62-4186-968a-f0ca625bc14e"
          method="POST"
        >
          <div className="field">
            <label className="label" htmlFor="name">
              Name
            </label>
            <div className="control">
              <input className="input" type="text" id="name" name="name" />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="email">
              Email
            </label>
            <div className="control">
              <input className="input" type="text" id="email" name="email" />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="message">
              Message
            </label>
            <div className="control">
              <textarea className="textarea" id="message" name="message" />
            </div>
            <input
              type="hidden"
              name="g-recaptcha-response"
              value={captchaResponse}
            />
          </div>
          <div className="field">
            <div className="control">
              <button className="button" type="submit">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};
